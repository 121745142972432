import React from 'react';

const Qualificacoes = () => (
  <div className="qualificacoes">
    <div className="card p-3 p-sm-4">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              className="rounded-circle"
              width="32"
              height="32"
              src="https://lunar-objects.s3.us-east-2.amazonaws.com/uploads/user/qaNDr8gmAgBmjde/plans-reviews/public/phpiktbvn.png"
              alt="Reclame Aqui"
            />
            <div className="ml-2">Reclame Aqui...</div>
          </div>
          <div className="stars" style={{ color: '#f8ce1c', fontSize: '20px' }}>
            {'★ ★ ★ ★ ★'}
          </div>
        </div>
        <div className="mt-2">
          Empresa com 100% de reclamações atendidas e respondidas.
        </div>
      </div>
    </div>

    <div className="card p-3 p-sm-4">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <img
              className="rounded-circle"
              width="32"
              height="32"
              src="https://lunar-objects.s3.us-east-2.amazonaws.com/uploads/user/qaNDr8gmAgBmjde/plans-reviews/public/phpmGVgHJ.png"
              alt="Verificação"
            />
            <div className="ml-2">Verificação...</div>
          </div>
          <div className="stars" style={{ color: '#f8ce1c', fontSize: '20px' }}>
            {'★ ★ ★ ★ ★'}
          </div>
        </div>
        <div className="mt-2">
          Empresa verificada e autorizada pelo Governo Federal para receber pagamentos.
        </div>
      </div>
    </div>
  </div>
);

export default Qualificacoes;