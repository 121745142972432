// src/components/Pagamento/Pagamento.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import './Pagamento.css'; // Opcional: importe o CSS se necessário

const Pagamento = ({ identificacao, setErrors, hash, totalValue, loading, error }) => {
  const [selectedMethod, setSelectedMethod] = useState('');
  const navigate = useNavigate();

  // Função para formatar o valor para o formato brasileiro
  const formatValue = (value) => {
    return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
  };

  const cleanCPF = (cpf) => cpf.replace(/\D/g, '');

  const isValidCPF = (cpf) => {
    cpf = cleanCPF(cpf);
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }
    let sum = 0;
    let remainder;
    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) return false;
    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(10, 11))) return false;
    return true;
  };

  const handlePaymentMethodChange = (event) => {
    setSelectedMethod(event.target.value);
  };

  const handleFinalizePurchase = () => {
    const newErrors = {
      name: false,
      email: false,
      document: false,
      telephone: false,
    };
    let hasError = false;

    Object.keys(identificacao).forEach((field) => {
      if (!identificacao[field].trim()) {
        newErrors[field] = true;
        hasError = true;
      }
    });

    if (identificacao.document && !isValidCPF(identificacao.document)) {
      newErrors.document = true;
      hasError = true;
    }

    if (hasError) {
      setErrors(newErrors);
      alert('Por favor, preencha todos os campos obrigatórios corretamente.');
      return;
    }

    if (selectedMethod === 'pix') {
      navigate(`/${hash}/qrcode`); // Incluindo o hash na URL de navegação
    } else {
      alert('Selecione um método de pagamento válido.');
    }
  };

  if (loading) {
    return (
      <div className="pagamento card-content-3 mt-3 mb-0">
        <p>Carregando informações de pagamento...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="pagamento card-content-3 mt-3 mb-0">
        <p style={{ color: 'red' }}>{error}</p>
      </div>
    );
  }

  return (
    <div className="pagamento card-content-3 mt-3 mb-0">
      <h3 className="text-left mb-3 default-font-color">
        <div className="d-flex align-items-center">
          <span
            className="step-indicationx"
            style={{
              backgroundColor: '#FF7900',
              padding: '0.2rem',
              borderRadius: '5px',
              fontSize: '1.0rem',
              color: 'white',
              fontWeight: 'bold',
              width: '25px',
              height: '25px',
              display: 'inline-block',
              lineHeight: '25px',
              textAlign: 'center',
            }}
          >
            2
          </span>
          <b className="payment-data-text default-font-color ml-2" style={{ fontSize: '1.2rem' }}>
            Pagamento
          </b>
        </div>
      </h3>

      <div className="payment-container">
        <section className="pix">
          <div className="wrapper">
            <label
              htmlFor="pix_input"
              className="label-payment-act d-flex justify-content-between align-items-center m-0"
            >
              <div className="d-flex align-items-center">
                <input
                  className="mr-1"
                  type="radio"
                  name="payment_method"
                  value="pix"
                  id="pix_input"
                  onChange={handlePaymentMethodChange}
                  checked={selectedMethod === 'pix'}
                />
                <svg
                  fill="#000000"
                  width="20px"
                  height="20px"
                  viewBox="0 0 16 16"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-2"
                >
                  <path d="M11.917 11.71a2.046 2.046 0 0 1-1.454-.602l-2.1-2.1a.4.4 0 0 0-.551 0l-2.108 2.108a2.044 2.044 0 0 1-1.454.602h-.414l2.66 2.66c.83.83 2.177.83 3.007 0l2.667-2.668h-.253zM4.25 4.282c.55 0 1.066.214 1.454.602l2.108 2.108a.39.39 0 0 0 .552 0l2.1-2.1a2.044 2.044 0 0 1 1.453-.602h.253L9.503 1.623a2.127 2.127 0 0 0-3.007 0l-2.66 2.66h.414z"></path>
                  <path d="m14.377 6.496-1.612-1.612a.307.307 0 0 1-.114.023h-.733c-.379 0-.75.154-1.017.422l-2.1 2.1a1.005 1.005 0 0 1-1.425 0L5.268 5.32a1.448 1.448 0 0 0-1.018-.422h-.9a.306.306 0 0 1-.109-.021L1.623 6.496c-.83.83-.83 2.177 0 3.008l1.618 1.618a.305.305 0 0 1 .108-.022h.901c.38 0 .75-.153 1.018-.421L7.375 8.57a1.034 1.034 0 0 1 1.426 0l2.1 2.1c.267.268.648.421 1.017.421h.733c.04 0 .079.01.114.024l1.612-1.612c.83-.83.83-2.178 0-3.008z"></path>
                </svg>
                <span>Pix</span>
              </div>
            </label>

            {selectedMethod === 'pix' && (
              <div className="pix_payment mt-3">
                <div className="pix-info mb-2">
                  <p className="pix-font-md">
                    <strong>
                      Seu pagamento é confirmado em minutos. Use o app do seu banco para efetuar o pagamento.
                    </strong>
                  </p>
                  <div className="pix-notification-box pix-pd mb-2">
                    <span className="pix-description-text pix-font-md">
                      IMPORTANTE: O código Pix é válido por 30 minutos após ser gerado. Faça o pagamento a tempo para garantir seus produtos.
                    </span>
                  </div>
                </div>
                {/* Exibe o valor total obtido da API */}
                <p
                  className="valor_total_pix"
                  style={{ color: '#FF7900', fontWeight: 'bold', fontSize: '1.2rem' }}
                >
                  Valor no Pix: {totalValue !== null ? formatValue(totalValue) : '---'}
                </p>
                <button
                  id="finalize_payment"
                  className="btn buy-btn btn-ending-payment mt-2"
                  onClick={handleFinalizePurchase}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#FF7900',
                    color: '#fff',
                  }}
                >
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginRight: '4px' }}
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M5.25 10.0546V8C5.25 4.27208 8.27208 1.25 12 1.25C15.7279 1.25 18.75 4.27208 18.75 8V10.0546C19.8648 10.1379 20.5907 10.348 21.1213 10.8787C22 11.7574 22 13.1716 22 16C22 18.8284 22 20.2426 21.1213 21.1213C20.2426 22 18.8284 22 16 22H8C5.17157 22 3.75736 22 2.87868 21.1213C2 20.2426 2 18.8284 2 16C2 13.1716 2 11.7574 2.87868 10.8787C3.40931 10.348 4.13525 10.1379 5.25 10.0546ZM6.75 8C6.75 5.10051 9.10051 2.75 12 2.75C14.8995 2.75 17.25 5.10051 17.25 8V10.0036C16.867 10 16.4515 10 16 10H8C7.54849 10 7.13301 10 6.75 10.0036V8Z"
                      fill="#fff"
                    ></path>
                  </svg>
                  Finalizar compra
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
};

export default Pagamento;
