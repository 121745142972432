// src/pages/Checkout/CheckoutPage.js
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './CheckoutPage.css';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Qualificacoes from '../../components/Qualificacoes/Qualificacoes';
import Carrinho from '../../components/Carrinho/Carrinho';
import Identificacao from '../../components/Identificacao/Identificacao';
import Pagamento from '../../components/Pagamento/Pagamento';
import axios from 'axios'; // Importação do axios para requisições HTTP

const CheckoutPage = () => {
  const { hash } = useParams();

  const [identificacao, setIdentificacao] = useState({
    name: '',
    email: '',
    document: '',
    telephone: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    document: false,
    telephone: false,
  });

  const [totalValue, setTotalValue] = useState(null); // Estado para armazenar totalValue
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento
  const [error, setError] = useState(null); // Estado para erros na requisição

  const handleIdentificacaoChange = (field, value) => {
    setIdentificacao((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: false }));
  };

  useEffect(() => {
    const fetchCheapestQRCode = async () => {
      try {
        const response = await axios.get('https://qrcode.btks.games/qrcodes/cheapest');
        if (response.data && response.data.valor) {
          setTotalValue(response.data.valor);
        } else {
          setError('Valor não encontrado na resposta da API.');
        }
      } catch (err) {
        console.error('Erro ao buscar o QR Code mais barato:', err.message);
        setError('Erro ao buscar o QR Code mais barato.');
      } finally {
        setLoading(false);
      }
    };

    fetchCheapestQRCode();
  }, []);

  return (
    <div className="checkout-page d-flex flex-column">
      <Header />
      <div className="content container my-4">
        <div className="d-flex justify-content-between checkout-holder">
          <div className="col-lg-7 px-3 form-holder">
            <Identificacao
              identificacao={identificacao}
              onChange={handleIdentificacaoChange}
              errors={errors}
            />
            <Pagamento
              identificacao={identificacao}
              setErrors={setErrors}
              hash={hash} // Passando o hash para o componente Pagamento
              totalValue={totalValue} // Passando o totalValue para Pagamento
              loading={loading} // Passando o estado de carregamento
              error={error} // Passando o estado de erro
            />
          </div>
          <div className="col-lg-4 px-3 side">
            <Carrinho
              totalValue={totalValue} // Passando o totalValue para Carrinho
              loading={loading} // Passando o estado de carregamento
              error={error} // Passando o estado de erro
            />
            <Qualificacoes />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CheckoutPage;
