// src/components/Header/Header.js
import React from 'react';
// import './Header.css'; // Opcional: importe o CSS se necessário

const Header = () => (
  <header>
    <div className="container">
      <div className="checkout-logo-container checkout-logo-container-mobile">
        <div className="checkout-logo-container-mobile-box d-flex align-items-center justify-content-between">
          <img
            className="checkout-logo"
            alt="Store Logo"
            src="https://lunar-objects.s3.us-east-2.amazonaws.com/uploads/user/qaNDr8gmAgBmjde/public/projects/Vn7vJOGYO3KXdax/logo/kXV1zKi4x9d5SZIytPI6VJWmTe2fwiqPUVn1GGQ2.png"
          />
          <button className="btn btn-security d-flex">
            <svg width="18" height="20" viewBox="0 0 18 20" fill="none">
              <path
                d="M17.25 3C14.5866 3 11.992 2.05652 9.45 0.15C9.18333 -0.05 8.81667 -0.05 8.55 0.15C6.00797 2.05652 3.41341 3 0.75 3C0.335786 3 0 3.33579 0 3.75V9C0 14.0012 2.95756 17.6757 8.72511 19.9478C8.90176 20.0174 9.09824 20.0174 9.27489 19.9478C15.0424 17.6757 18 14.0012 18 9V3.75C18 3.33579 17.6642 3 17.25 3ZM13.7568 7.30287L7.75679 12.8029C7.46079 13.0742 7.0036 13.0643 6.71967 12.7803L4.21967 10.2803C3.92678 9.98744 3.92678 9.51256 4.21967 9.21967C4.51256 8.92678 4.98744 8.92678 5.28033 9.21967L7.27256 11.2119L12.7432 6.19714C13.0485 5.91724 13.523 5.93787 13.8029 6.24321C14.0828 6.54855 14.0621 7.02297 13.7568 7.30287Z"
                fill="#6ACD85"
              />
            </svg>
            <div className="ml-2">Ambiente seguro</div>
          </button>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
