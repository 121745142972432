// src/components/MetaPixel.jsx
import { useEffect } from 'react';

function MetaPixel() {
  useEffect(() => {
    // Adiciona o script do Meta Pixel
    ;(function(f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function() {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

    // Inicializa e rastreia o PageView usando window.fbq
    window.fbq('init', '525779703627143');
    window.fbq('track', 'PageView');

    // Função de limpeza para remover o script quando o componente for desmontado
    return () => {
      // Remove o script do Meta Pixel
      const script = document.querySelector('script[src="https://connect.facebook.net/en_US/fbevents.js"]');
      if (script) {
        script.remove();
      }

      // Remove a imagem de noscript, se existir
      const noscriptImg = document.querySelector('noscript img[src*="facebook.com/tr"]');
      if (noscriptImg) {
        noscriptImg.remove();
      }

      // Remove a função fbq
      if (window.fbq) {
        window.fbq = null;
      }
    };
  }, []);

  // O conteúdo <noscript> não funcionará corretamente dentro de componentes React.
  // É recomendado manter o <noscript> no index.html se necessário.
  return null;
}

export default MetaPixel;
