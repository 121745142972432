// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/components/Identificacao/Identificacao.css */

/* Destacar campos inválidos */
input.invalid {
    border-color: #d10000;
  }
  
  /* Exibir mensagens de erro */
  .invalid-feedback {
    color: #d10000;
    font-size: 0.875em;
    margin-top: 0.25rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/Identificacao/Identificacao.css"],"names":[],"mappings":"AAAA,mDAAmD;;AAEnD,8BAA8B;AAC9B;IACI,qBAAqB;EACvB;;EAEA,6BAA6B;EAC7B;IACE,cAAc;IACd,kBAAkB;IAClB,mBAAmB;EACrB","sourcesContent":["/* src/components/Identificacao/Identificacao.css */\n\n/* Destacar campos inválidos */\ninput.invalid {\n    border-color: #d10000;\n  }\n  \n  /* Exibir mensagens de erro */\n  .invalid-feedback {\n    color: #d10000;\n    font-size: 0.875em;\n    margin-top: 0.25rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
