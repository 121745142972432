import React from 'react';
import './Footer.css';

const Footer = () => (
  <footer id="rodape">
    <div className="recipiente largura-maxima">
      {/* Seção Fale Conosco */}
      <div className="rodape-links">
        <h2>Fale Conosco</h2>
        <ul className="lista-icone">
          <li>
            <a href="https://faleconosco.correios.com.br/faleconosco/app/index.php" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/monitor.png" alt="Monitor Icon" />
              <span>Registro de Manifestações</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/falecomoscorreios/central-de-atendimento" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/duvida.png" alt="Dúvida Icon" />
              <span>Central de Atendimento</span>
            </a>
          </li>
          <li>
            <a href="https://faleconosco.correios.com.br/faleconosco/app/cadastro/contrate/index.php" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/negocios.png" alt="Negócios Icon" />
              <span>Soluções para o seu negócio</span>
            </a>
          </li>
          <li>
            <a href="https://faleconosco.correios.com.br/faleconosco/app/cadastro/suporte/index.php" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/headset.png" alt="Headset Icon" />
              <span>Suporte ao cliente com contrato</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/falecomoscorreios/ouvidoria" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/ouvidoria.png" alt="Ouvidoria Icon" />
              <span>Ouvidoria</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/falecomoscorreios/canais-de-denuncia" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/denuncia.png" alt="Denúncia Icon" />
              <span>Denúncia</span>
            </a>
          </li>
        </ul>
      </div>

      {/* Seção Sobre os Correios */}
      <div className="rodape-links">
        <h2>Sobre os Correios</h2>
        <ul className="lista-icone">
          <li>
            <a href="https://www.correios.com.br/acesso-a-informacao/institucional/identidade-corporativa" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/identidade.png" alt="Identidade Icon" />
              <span>Identidade corporativa</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/educacao-e-cultura" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/educa%C3%A7%C3%A3o.png" alt="Educação Icon" />
              <span>Educação e cultura</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/acesso-a-informacao/servidores/arquivos/codigo-de-conduta-etica-dos-correios.pdf" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/c%C3%B3digo%20%C3%A9tica.png" alt="Ética Icon" />
              <span>Código de ética</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/acesso-a-informacao/institucional/publicacoes/processos-de-contas-anuais" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/Transpar%C3%AAncia.png" alt="Transparência Icon" />
              <span>Transparência e prestação de contas</span>
            </a>
          </li>
          <li>
            <a href="https://www.correios.com.br/falecomoscorreios/politica-de-privacidade-e-cookies" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/cadeado.png" alt="Privacidade Icon" />
              <span>Política de Privacidade e Notas Legais</span>
            </a>
          </li>
        </ul>
      </div>

      {/* Seção Outros Sites */}
      <div className="rodape-links">
        <h2>Outros Sites</h2>
        <ul className="lista-icone">
          <li>
            <a href="http://shopping.correios.com.br/wbm/store/script/store.aspx?cd_company=ErZW8Dm9i54=" target="_blank" rel="noopener noreferrer">
              <img className="icones-link" src="https://rastreamento.correios.com.br/core/templates/bunker/img/rodape/loja%20correios.png" alt="Loja Icon" />
              <span>Loja online dos Correios</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div className="marca-gov"></div>
    <div className="copyright">© Copyright 2024 Correios</div>
  </footer>
);

export default Footer;
