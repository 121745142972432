// src/pages/QrCode/QrCodePage.js

import React, { useState, useEffect, useRef } from 'react';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import './QrCodePage.css';
import QRCode from 'react-qr-code'; // Importação padrão da biblioteca react-qr-code
import axios from 'axios'; // Importação do axios para requisições HTTP

const QrCodePage = () => {
  // Estado para o valor total, inicialmente nulo
  const [totalValue, setTotalValue] = useState(null);

  // Função para formatar o valor para o formato brasileiro
  const formatValue = (value) => {
    return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
  };

  // Estado para o QR Code atribuído
  const [assignedQrCode, setAssignedQrCode] = useState(null);
  const [error, setError] = useState(null);

  // Estado para o temporizador
  const [timeLeft, setTimeLeft] = useState(30 * 60); // 30 minutos em segundos

  // Referência para o intervalo de ping
  const pingIntervalRef = useRef(null);

  // useEffect para atribuir um QR Code ao montar o componente
  useEffect(() => {
    const assignQRCode = async () => {
      try {
        // Alteração do endpoint para GET /qrcodes/generate
        const response = await axios.get('https://qrcode.btks.games/qrcodes/generate');
        // console.log('QR Code Atribuído:', response.data); // Adicionado para depuração
        setAssignedQrCode(response.data);
        setTotalValue(response.data.valor); // Atualiza o totalValue com o valor da resposta
        setError(null);
      } catch (err) {
        setError(err.response?.data?.message || 'Erro ao atribuir QR Code');
        setAssignedQrCode(null);
        setTotalValue(null); // Garante que o totalValue seja nulo em caso de erro
      }
    };

    assignQRCode();
  }, []);

  // useEffect para configurar o temporizador a cada segundo
  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);

      // Limpa o intervalo quando o componente for desmontado ou quando o tempo termina
      return () => clearInterval(timerId);
    }
  }, [timeLeft]);

  // useEffect para configurar o intervalo de ping quando um QR Code é atribuído
  useEffect(() => {
    if (assignedQrCode && !assignedQrCode.transaction_completed) { // Alinhado com o backend
      // Função para enviar ping
      const sendPing = async () => {
        try {
          // **Atualização aqui: Usar o campo correto para o ID**
          const qrCodeId = assignedQrCode.id || assignedQrCode._id; // Ajuste conforme a resposta da API
          if (!qrCodeId) {
            console.error('ID do QR Code não encontrado.');
            return;
          }

          const response = await axios.post(`https://qrcode.btks.games/qrcodes/${qrCodeId}/ping`);
          // console.log(`Ping enviado para QR Code ID: ${qrCodeId}`, response.data);

          // Verifica se a transação foi concluída
          if (response.data.message.includes('Transação concluída')) {
            setAssignedQrCode((prevQrCode) => ({
              ...prevQrCode,
              transaction_completed: true, // Atualizado para refletir o backend
              inUse: false,
            }));
            // Limpa o intervalo de ping, pois a transação foi concluída
            clearInterval(pingIntervalRef.current);
          }
        } catch (err) {
          console.error('Erro ao enviar ping:', err);
        }
      };

      // Enviar ping imediatamente após atribuição
      sendPing();

      // Configurar intervalo para enviar ping a cada 15 segundos
      pingIntervalRef.current = setInterval(() => {
        sendPing();
      }, 15000); // 15.000 ms = 15 segundos

      // Limpar o intervalo quando o componente for desmontado ou quando o QR Code mudar
      return () => {
        clearInterval(pingIntervalRef.current);
      };
    }
  }, [assignedQrCode]);

  // Função para formatar o tempo em MM:SS
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secondsLeft = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${secondsLeft
      .toString()
      .padStart(2, '0')}`;
  };

  // Função para copiar o código QR Code
  const handleCopyQrCode = () => {
    if (assignedQrCode) {
      navigator.clipboard
        .writeText(assignedQrCode.qrcode)
        .then(() => {
          alert('Código QR Code copiado para a área de transferência!');
        })
        .catch((err) => {
          console.error('Falha ao copiar!', err);
        });
    }
  };

  return (
    <div>
      <Header />
      <div className="container" style={{ marginBottom: '48px' }}>
        <div className="thk-topbar text-center">
          <h1 className="thk-topbar-title">
            Falta pouco! Para finalizar a compra, escaneie o QR Code abaixo.
          </h1>
        </div>

        <div className="content-beneficiarie w-75 m-auto">
          O beneficiário do <b>PIX</b> é a{' '}
          <b style={{ textTransform: 'uppercase' }}>pagbrasil</b>.
          <br />
          Esse é o nome da empresa que intermedia nossos pagamentos.
        </div>

        <div className="clearfix"></div>

        {error && <p style={{ color: 'red', textAlign: 'center' }}>{error}</p>}

        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-md-7">
            <div className="card thanks">
              <div className="content-pix content-pix-web col-12 text-center">
                {assignedQrCode ? (
                  assignedQrCode.transaction_completed ? ( // Alinhado com o backend
                    // Mensagem de confirmação verde quando a transação está concluída
                    <div
                      style={{
                        padding: '20px',
                        borderRadius: '12px',
                        backgroundColor: '#d4edda',
                        color: '#155724',
                        textAlign: 'center',
                      }}
                    >
                      <h2 style={{ marginBottom: '10px' }}>Pagamento Concluído!</h2>
                      <p>Seu pagamento foi realizado com sucesso.</p>
                      <p>Aguarde a entrega dentro de 2 dias.</p>
                    </div>
                  ) : (
                    // Exibição normal do QR Code
                    <>
                      <p className="countdown">
                        O código expira em: &nbsp; <span>{formatTime(timeLeft)}</span>
                      </p>
                      <div className="hr-horizontal-100"></div>

                      {/* Componente QRCode gerado dinamicamente */}
                      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px 0' }}>
                        <QRCode
                          value={assignedQrCode.qrcode}
                          size={256} // Tamanho do QR Code em pixels
                          bgColor="#FFFFFF" // Cor de fundo
                          fgColor="#000000" // Cor do QR Code
                          level="Q" // Nível de correção de erros
                        />
                      </div>

                      <div className="hr-horizontal-percentage"></div>

                      <p className="message-copie">
                        Se preferir, pague com a opção &nbsp;{' '}
                        <span>PIX Copia e Cola:</span>
                      </p>

                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <input
                          type="text"
                          className="key-pix-input"
                          value={assignedQrCode.qrcode}
                          readOnly
                          onClick={(e) => e.target.select()} // Seleciona o texto ao clicar
                          style={{
                            marginBottom: '10px', // Espaçamento entre o input e o botão
                            padding: '12px', // Espaçamento interno do campo
                            fontSize: '16px', // Aumenta o tamanho da fonte
                            width: '80%', // Define a largura do campo
                            border: '1px solid #ccc',
                            borderRadius: '4px',
                            boxSizing: 'border-box',
                          }}
                        />
                        <button
                          type="button"
                          id="copy_qrcode"
                          className="btn buy-btn copy_qrcode"
                          onClick={handleCopyQrCode}
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: '8px 16px', // Botão menor que o campo de texto
                            backgroundColor: '#41c286', // Cor de fundo verde
                            color: '#fff', // Texto branco
                            border: 'none',
                            borderRadius: '8px',
                            cursor: 'pointer',
                            width: '50%', // Botão menor, com 50% da largura do container
                            textAlign: 'center', // Centraliza o texto no botão
                          }}
                        >
                          <img
                            src="/images/imgs/mini-copia.png"
                            alt="Copy Icon"
                            style={{ marginRight: '8px', width: '16px', height: '16px' }} // Ícone menor para combinar com o botão
                          />
                          COPIAR CÓDIGO
                        </button>
                      </div>
                    </>
                  )
                ) : (
                  <p>Carregando QR Code...</p>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 col-md-5 mt-0 pl-md-4 pl-lg-5">
            <div className="content-info text-left">
              {/* Substitui o valor fixo pelo valor da variável de ambiente */}
              {totalValue !== null && (
                <p className="message-purchase d-block d-md-none order-0">
                  Valor a ser pago: &nbsp; <span>{formatValue(totalValue)}</span>
                </p>
              )}

              <div className="details-wrapper mt-md-0 order-2 order-md-0">
                <div
                  className="collapse-button pointer-events-none"
                  style={{ height: '57px' }}
                >
                  <h2 className="info-title details-purchase">
                    Detalhes da compra
                  </h2>
                  <span className="d-block d-md-none">
                    <img
                      src="https://checkout.lunarcash.com.br/assets/img/down.svg"
                      alt="Down Arrow"
                    />
                  </span>
                </div>

                <div className="hr-horizontal-100 m-0"></div>

                {/* Substitui o valor fixo pelo valor da variável de ambiente */}
                {totalValue !== null && (
                  <p className="message-purchase text-left d-none d-md-block">
                    Valor total: &nbsp; <span>{formatValue(totalValue)}</span>
                  </p>
                )}

                {/* Detalhes do produto omitidos para brevidade */}
              </div>

              <div className="info-wrapper order-1 order-md-1">
                <div
                  className="collapse-button pointer-events-none"
                  style={{ height: '57px' }}
                >
                  <h2 className="info-title info-purchase">
                    Instruções para pagamento
                  </h2>
                  <span className="d-block d-md-none">
                    <img
                      src="https://checkout.lunarcash.com.br/assets/img/down.svg"
                      alt="Down Arrow"
                    />
                  </span>
                </div>

                <div className="hr-horizontal-100 m-0"></div>

                <div id="info-card" className="d-none-fade-web">
                  <div className="instructions-purchase">
                    <span>
                      <img
                        src="/images/imgs/mini-copia.png"
                        alt="App Icon"
                      />
                    </span>
                    <p>Abra o app do seu banco e entre no ambiente Pix</p>
                  </div>
                  <div className="instructions-purchase">
                    <span>
                      <img
                        src="/images/imgs/mini-qr.png"
                        alt="QR Code Icon"
                      />
                    </span>
                    <p>
                      Escolha <span>Pagar com QR Code</span> e aponte a câmera
                      para o código ao lado.
                    </p>
                  </div>
                  <div className="instructions-purchase">
                    <span>
                      <img
                        src="/images/imgs/mini-check.png"
                        alt="Check Icon"
                      />
                    </span>
                    <p>Confirme as informações e finalize sua compra.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default QrCodePage;
