import React, { useState, useEffect } from 'react';
import './RastreamentoDetalhes.css';

// Mapeamento de nomes de estados para suas siglas
const stateAbbreviations = {
  "Acre": "AC",
  "Alagoas": "AL",
  "Amapá": "AP",
  "Amazonas": "AM",
  "Bahia": "BA",
  "Ceará": "CE",
  "Distrito Federal": "DF",
  "Espírito Santo": "ES",
  "Goiás": "GO",
  "Maranhão": "MA",
  "Mato Grosso": "MT",
  "Mato Grosso do Sul": "MS",
  "Minas Gerais": "MG",
  "Pará": "PA",
  "Paraíba": "PB",
  "Paraná": "PR",
  "Pernambuco": "PE",
  "Piauí": "PI",
  "Rio de Janeiro": "RJ",
  "Rio Grande do Norte": "RN",
  "Rio Grande do Sul": "RS",
  "Rondônia": "RO",
  "Roraima": "RR",
  "Santa Catarina": "SC",
  "São Paulo": "SP",
  "Sergipe": "SE",
  "Tocantins": "TO"
};

// Função para formatar a data no formato 'dd/mm/yyyy hh:mm'
const formatDate = (date) => {
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Os meses começam em 0
  const year = date.getFullYear();
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
};

// Função para subtrair dias, horas e minutos de uma data
const subtractTime = (date, days = 0, hours = 0, minutes = 0) => {
  const newDate = new Date(date);
  newDate.setDate(newDate.getDate() - days);
  newDate.setHours(newDate.getHours() - hours);
  newDate.setMinutes(newDate.getMinutes() - minutes);
  return newDate;
};

const RastreamentoDetalhes = () => {
  const [copied, setCopied] = useState(false);
  const [userLocation, setUserLocation] = useState(null); // Estado para armazenar a localização do usuário
  const [isLoading, setIsLoading] = useState(true); // Estado de carregamento
  const trackingCode = "NC 231 612 509 BR";

  useEffect(() => {
    let isMounted = true; // Para evitar atualizações de estado se o componente for desmontado
    const timeoutId = setTimeout(() => {
      if (isMounted && !userLocation) {
        // Se após 3 segundos a localização não foi obtida, definir como Curitiba
        setUserLocation({
          city: "Curitiba",
          region: "Paraná",
          stateAbbr: "PR",
        });
        setIsLoading(false);
      }
    }, 3000);

    // Função para obter a localização do usuário
    const fetchLocation = async () => {
      try {
        const response = await fetch('https://ipinfo.io/json?token=9bc37b65296ab3'); // Substitua YOUR_TOKEN_AQUI pelo seu token do ipinfo.io
        if (!response.ok) {
          throw new Error('Falha ao obter a localização');
        }
        const data = await response.json();

        // Obtém a sigla do estado usando o mapeamento
        const stateName = data.region;
        const stateAbbr = stateAbbreviations[stateName] || ''; // Retorna a sigla ou uma string vazia se não encontrado

        if (isMounted) {
          setUserLocation({
            city: data.city,
            region: stateName,
            stateAbbr: stateAbbr,
          });
          setIsLoading(false);
          clearTimeout(timeoutId); // Limpar o timeout se a localização foi obtida a tempo
        }
      } catch (error) {
        console.error('Erro ao buscar localização:', error);
        if (isMounted) {
          // Definir localização padrão em caso de erro
          setUserLocation({
            city: "Curitiba",
            region: "Paraná",
            stateAbbr: "PR",
          });
          setIsLoading(false);
        }
      }
    };

    fetchLocation();

    return () => {
      isMounted = false;
      clearTimeout(timeoutId); // Limpar o timeout ao desmontar o componente
    };
  }, []); // Executa apenas uma vez ao montar

  const copyToClipboard = (e) => {
    e.preventDefault(); // Previne o comportamento padrão do botão

    if (navigator.clipboard && window.isSecureContext) {
      // Usa a API moderna de clipboard
      navigator.clipboard.writeText(trackingCode)
        .then(() => {
          setCopied(true);
          // Ocultar a mensagem após 3 segundos
          setTimeout(() => setCopied(false), 3000);
        })
        .catch(err => {
          console.error('Falha ao copiar o código: ', err);
        });
    } else {
      // Fallback para navegadores que não suportam a API moderna
      const textArea = document.createElement("textarea");
      textArea.value = trackingCode;
      // Evita que o textarea seja exibido na tela
      textArea.style.position = "fixed";
      textArea.style.top = "-1000px";
      textArea.style.left = "-1000px";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        if (successful) {
          setCopied(true);
          setTimeout(() => setCopied(false), 3000);
        } else {
          console.error('Falha ao copiar o código.');
        }
      } catch (err) {
        console.error('Erro ao copiar o código: ', err);
      }

      document.body.removeChild(textArea);
    }
  };

  // Cálculo dinâmico das datas com base na data atual
  const hoje = new Date();
  const formattedHoje = formatDate(hoje);

  // 1ª data: 14 dias atrás, 2 horas e 3 minutos de diferença
  const data1 = subtractTime(hoje, 4, 1, 10);
  const formattedData1 = formatDate(data1);

  // 2ª data: 13 dias atrás, 4 horas de diferença
  const data2 = subtractTime(hoje, 5, 3, 13);
  const formattedData2 = formatDate(data2);

  // 3ª data: 6 dias atrás, 15 minutos de diferença
  const data3 = subtractTime(hoje, 6, 0, 15);
  const formattedData3 = formatDate(data3);

  // 4ª data: 5 dias atrás, 3 horas e 13 minutos de diferença
  const data4 = subtractTime(hoje, 13, 4, 0);
  const formattedData4 = formatDate(data4);

  // 5ª data: 4 dias atrás, 1 hora e 10 minutos de diferença
  const data5 = subtractTime(hoje, 14, 2, 3);
  const formattedData5 = formatDate(data5);

  // Define os passos de rastreamento, substituindo a localização se disponível
  const steps = [
    {
      title: "Aguardando Pagamento",
      location: userLocation ? `${userLocation.city} - ${userLocation.stateAbbr || "PR"}` : "Curitiba - PR",
      date: formattedData1, // Substituído para data dinâmica
      img: "/images/banners/correios-sf.png",
      active: true,
    },
    {
      title: "Encaminhado para fiscalização aduaneira",
      location: "Curitiba - PR",
      date: formattedData2, // Substituído para data dinâmica
      img: "/images/imgs/agencia-cor.png",
    },
    {
      title: "Objeto recebido pelos Correios do Brasil",
      location: "Curitiba - PR",
      date: formattedData3, // Substituído para data dinâmica
      img: "/images/banners/brazil.png",
    },
    {
      title: "Objeto em trânsito - Por favor aguarde",
      location: "Para Unidade de Tratamento internacional - BR",
      date: formattedData4, // Substituído para data dinâmica
      img: "/images/banners/caminhao-cor.png",
    },
    {
      title: "Objeto Postado",
      location: "CHINA",
      date: formattedData5, // Substituído para data dinâmica
      img: "/images/imgs/agencia-cor.png",
    },
  ];

  if (isLoading) {
    return (
      <main>
        <div className="container delivery-history-container">
          <div className="loading">
            {/* <p>Carregando localização...</p> */}
            {/* Você pode adicionar um spinner ou animação aqui */}
          </div>
        </div>
      </main>
    );
  }

  return (
    <main>
      <div className="container delivery-history-container">
        {/* Prazo de Entrega */}
        <div className="delivery-time">
          <p className="delivery-text">
            Prazo de entrega após o pagamento: <strong>2 dias úteis</strong>
          </p>
        </div>

        {/* Histórico de Movimentações */}
        <div className="movement-history">
          <h4>Histórico de Movimentações</h4>
          <ul className="steps-list">
            {steps.map((step, index) => (
              <li key={index} className={`step ${step.active ? "active" : ""}`}>
                <div className="step-icon">
                  <img src={step.img} alt={step.title} />
                </div>
                <div className="step-info">
                  <p className="step-title">{step.title}</p>
                  <p className="step-details">
                    {step.location}<br />{step.date}
                  </p>
                </div>
              </li>
            ))}
          </ul>
          <div className="share-bar">
            <button
              onClick={copyToClipboard}
              title="Compartilhar"
              className="share-button"
              type="button" // Assegura que o botão não seja do tipo submit
            >
              <i className="fa fa-share-alt" aria-hidden="true"></i> Compartilhar
            </button>
          </div>
        </div>
      </div>
      {/* Pop-up de Confirmação */}
      {copied && (
        <div className="copy-popup">
          Código da encomenda copiado com sucesso: {trackingCode}
        </div>
      )}
    </main>
  );
};

export default RastreamentoDetalhes;
