import React, { useState } from 'react';
import './CorreioHeader.css'; // Importação para estilos externos.

const CorreioHeader = () => {
  const [isAcessibilidadeOpen, setIsAcessibilidadeOpen] = useState(false);

  const toggleAcessibilidade = () => {
    setIsAcessibilidadeOpen(!isAcessibilidadeOpen);
  };

  return (
    <header id="header-acessibilidade">
      {/* Seção de Acessibilidade */}
      <section id="acessibilidade">
        <button 
          onClick={toggleAcessibilidade} 
          tabIndex="1" 
          aria-haspopup="true" 
          aria-expanded={isAcessibilidadeOpen}
          className="acessibilidade-button"
        >
          Acessibilidade
          <span className="arrow-down"></span>
        </button>
        {isAcessibilidadeOpen && (
          <div id="acess-drop-down" className="acessibilidade-menu">
            <ul>
              <li>
                <a href="#contraste-alto" tabIndex="1">Contraste Alto</a>
              </li>
              <li>
                <a href="#texto-maior" tabIndex="1">Texto Maior</a>
              </li>
              {/* Opções de Idiomas */}
              <li>
                <a href="#portugues" tabIndex="1" className="opcao-idioma">
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/en/0/05/Flag_of_Brazil.svg" 
                    alt="Bandeira do Brasil" 
                    className="bandeira-idioma"
                  /> 
                  Português
                </a>
              </li>
              <li>
                <a href="#ingles" tabIndex="1" className="opcao-idioma">
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg" 
                    alt="Flag of the United States" 
                    className="bandeira-idioma"
                  /> 
                  Inglês (US)
                </a>
              </li>
              <li>
                <a href="#espanhol" tabIndex="1" className="opcao-idioma">
                  <img 
                    src="https://upload.wikimedia.org/wikipedia/en/9/9a/Flag_of_Spain.svg" 
                    alt="Flag of Spain" 
                    className="bandeira-idioma"
                  /> 
                  Espanhol
                </a>
              </li>
            </ul>
          </div>
        )}
      </section>

      {/* Menu Principal */}
      <div id="menu">
        <a className="hamburger" tabIndex="1" aria-label="Abrir menu"></a>
        <a className="logo" href="https://www.correios.com.br" aria-label="Correios">
          <img
            src="https://rastreamento.correios.com.br/core/templates/bunker/img/logo-ect.svg"
            alt="Correios"
          />
        </a>
        <a
          className="entrar"
          href="https://rastreamento.correios.com.br/core/seguranca/entrar.php"
          tabIndex="1"
        >
          Entrar
        </a>
      </div>
    </header>
  );
};

export default CorreioHeader;
