// src/components/Identificacao/Identificacao.js
import React from 'react';
import './Identificacao.css'; // Opcional: importe o CSS se necessário

const Identificacao = ({ identificacao, onChange, errors }) => (
  <div className="identificacao card mb-4 p-3 p-sm-4">
    <div className="card-content-1">
      <h3 className="text-left mb-3 default-font-color">
        <div>
          <span className="step-indication" style={{ backgroundColor: '#FF7900' }}>
            1
          </span>
          <b className="payment-data-text default-font-color" style={{ marginLeft: '6px' }}>
            Identificação
          </b>
        </div>
      </h3>
      <div className="row" style={{ marginTop: '2rem' }}>
        <div className="col-12 col-md-6 d-flex flex-column mb-3">
          <label htmlFor="name" className="label_contact">
            Nome completo <span style={{ color: '#d10000' }}>*</span>
          </label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="Nome e Sobrenome"
            maxLength="100"
            value={identificacao.name}
            onChange={(e) => onChange('name', e.target.value)}
            className={errors.name ? 'invalid' : ''}
          />
          {errors.name && <small className="invalid-feedback">Este campo é obrigatório.</small>}
        </div>
        <div className="col-12 col-md-6 d-flex flex-column mb-3">
          <label htmlFor="email" className="label_contact">
            E-mail <span style={{ color: '#d10000' }}>*</span>
          </label>
          <input
            type="email"
            name="email"
            id="email"
            placeholder="email@email.com"
            maxLength="60"
            autoComplete="off"
            value={identificacao.email}
            onChange={(e) => onChange('email', e.target.value)}
            className={errors.email ? 'invalid' : ''}
          />
          {errors.email && <small className="invalid-feedback">Este campo é obrigatório.</small>}
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6 d-flex flex-column mb-3">
          <label htmlFor="document" className="label-document label_contact">
            CPF <span style={{ color: '#d10000' }}>*</span>
          </label>
          <input
            type="tel"
            name="document"
            id="document"
            placeholder="123.456.789-12"
            value={identificacao.document}
            onChange={(e) => onChange('document', e.target.value)}
            className={errors.document ? 'invalid' : ''}
          />
          {errors.document && <small className="invalid-feedback">Este campo é obrigatório e deve ser válido.</small>}
        </div>
        <div className="col-12 col-md-6 d-flex flex-column mb-3">
          <label htmlFor="telephone" className="label_contact">
            Celular / Whatsapp <span style={{ color: '#d10000' }}>*</span>
          </label>
          <input
            type="tel"
            id="telephone"
            name="telephone"
            placeholder="(99) 99999-9999"
            maxLength="15"
            value={identificacao.telephone}
            onChange={(e) => onChange('telephone', e.target.value)}
            className={errors.telephone ? 'invalid' : ''}
          />
          {errors.telephone && <small className="invalid-feedback">Este campo é obrigatório.</small>}
        </div>
      </div>
    </div>
  </div>
);

export default Identificacao;