import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './RastreioPage.css';

const RastreioPage = () => {
  const navigate = useNavigate();
  const { hash } = useParams();

  const cleanCPF = (cpf) => cpf.replace(/\D/g, '');

  const isValidCPF = (cpf) => {
    cpf = cleanCPF(cpf);

    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }

    let sum = 0;
    let remainder;

    for (let i = 1; i <= 9; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(9, 10))) {
      return false;
    }

    sum = 0;
    for (let i = 1; i <= 10; i++) {
      sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.substring(10, 11))) {
      return false;
    }

    return true;
  };

  const handleCheckDelivery = () => {
    const cpfInput = document.getElementById('client').value;

    if (isValidCPF(cpfInput)) {
      navigate(`/${hash}/rastreio`);
    } else {
      alert('Encomenda não encontrada! Por favor, insira um CPF válido!');
    }
  };

  return (
    <div className="rastreio-page bg-yellow">
      <div className="container mx-auto flex flex-col items-center justify-center min-h-[100dvh] py-12">
        <main>
          <div className="flex items-center justify-center">
            <img
              alt="Logo"
              loading="lazy"
              width="64"
              height="64"
              className="mr-2"
              src="/images/imgs/logo.webp"
            />
            <div className="flex flex-col">
              <p className="font-bold text-3xl text-[#14243E]">Rastreio</p>
              <small className="text-[#14243E]">Verifique onde seu produto se encontra!</small>
            </div>
          </div>

          <div className="flex flex-col items-center justify-center h-full w-full flex-grow mt-12 px-4 md:px-6">
            <div className="max-w-2xl text-center space-y-8">
              <h1 className="text-3xl font-bold text-white sm:text-3xl md:text-4xl" id="diminuir">
                Insira seu CPF para verificar o status do seu pedido!
              </h1>

              <input
                type="text"
                id="client"
                name="client"
                placeholder="000.000.000-00"
                maxLength="14"
                className="input-cpf"
              />

              <button
                id="redirecionamento"
                onClick={handleCheckDelivery}
                className="uppercase pulse-button inline-flex h-12 items-center justify-center rounded-md bg-green px-8 text-sm font-medium text-white shadow-md transition-colors hover:bg-light-green focus:outline-none focus:ring-2 focus:ring-light-green focus:ring-offset-2"
              >
                VERIFICAR MINHA ENTREGA!
              </button>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default RastreioPage;
