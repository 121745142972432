// src/components/Carrinho/Carrinho.js
import React from 'react';
// import './Carrinho.css'; // Opcional: importe o CSS se necessário

const Carrinho = ({ totalValue, loading, error }) => {
  // Função para formatar o valor para o formato brasileiro
  const formatValue = (value) => {
    return `R$ ${parseFloat(value).toFixed(2).replace('.', ',')}`;
  };

  if (loading) {
    return (
      <div className="carrinho card produto mb-4">
        <p>Carregando carrinho...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="carrinho card produto mb-4">
        <p style={{ color: 'red' }}>{error}</p>
      </div>
    );
  }

  return (
    <div className="carrinho card produto mb-4">
      <div className="row d-flex justify-content-between sidetop">
        <div className="col-6 cart">Seu carrinho</div>
        <div className="col-6">
          <div className="qtde text-center">1</div>
        </div>
      </div>
      <div className="product-list">
        <div className="product-grid">
          <div className="d-flex align-items-center">
            <img
              className="product-img"
              src="https://lunar-objects.s3.us-east-2.amazonaws.com/uploads/public/products/cirQuPKBx3yBjgBKLgzadGuvJYCQ415MFz3DHcHc.png"
              alt="Produto"
            />
          </div>
          <div className="d-flex align-items-center">
            <div>
              <span className="name_product_card ellipsis-h">TAXA ALFANDEGÁRIA</span>
              <span className="info-small ellipsis-w">TAXA ALFANDEGÁRIA</span>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-end">
            <span className="p-amount">1 un.</span>
          </div>
        </div>
      </div>
      <hr />
      <div className="cp-subtotal mb-1">
        <div className="row justify-content-between p-0 mb-2">
          <div className="col-6 text-left">
            <span className="subtotal">Subtotal</span>
          </div>
          <div className="col-6 text-right">
            {/* Exibe o valor total obtido da API */}
            <span className="subtotal">{formatValue(totalValue)}</span>
          </div>
        </div>
      </div>
      <div className="cp-total" style={{ position: 'relative' }}>
        <div className="row justify-content-between total_container">
          <div className="col-6 text-left">Total</div>
          <div className="col-6 text-right">
            {/* Exibe o valor total obtido da API */}
            <span className="valor_total">{formatValue(totalValue)}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carrinho;
